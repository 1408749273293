.userinfo_container{
    width: 200px;
}
.userinfo{

    width: 100%;
    background:var(--bg-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.walletLogin_tit{
    margin-top: 40px;
}
.walletLogin{

    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding:8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    color: var(--main-color);

}
.walletLogin_btn{
    cursor: pointer;
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    color: var(--main-color);
    background: var(--bg-color);

}
.left_avatar{
    display: block;
  
}
.left_nickname{
    margin-top: 20px;
}
.left_user_avatar{
    overflow: hidden;
    border-radius: 100px;
}
.left_address{

    color: var(--main-color);
    margin-left: 20px;

}
.left_bio{
    text-align: center;
    width: 80%;
    font-size: 14px;
    padding: 20px 0px;
}
.editProfile{
    cursor: pointer;
    width: 160px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-radius: 6px;

}
@media only screen and (max-width: 576px) {
 
    .userinfo_container{
        max-width: 100%;
        width: 100%;
        margin-top: 20px;
    }

    
}
