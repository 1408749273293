.profile_input{
    border: 1px solid #e5e7eb;
    height: 40px;
    line-height: 40px;
    margin-bottom: 20px;
}
.profile_textare{
    border: 1px solid #e5e7eb;
    margin-bottom: 20px;
}
.profile_publish{
    cursor: pointer;
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-radius: 6px;
    background: #000;
    color: #fff;
    margin-right: 40px;
}
.profile_error{
    width: 100%;
    text-align: center;
    color: #FF6464;
}