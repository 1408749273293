@font-face {
  font-family: fac;
  src: url("./assets/font/GDSherpaBold.ttf");
}




*{
  
  font-family:fac;

}
body {
  color: #111827;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}







code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* @import '~antd/dist/antd.css'; */
/* @import url('https://fonts.font.im/css?family=Poppins'); */
/* @import url('https://fonts.font.im/css?family=Candal'); */
