.tags{
   
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.tags li{
    margin-bottom: 8px;;
}
.tag_uncheck{
    cursor: pointer;
    font-weight: bold;
    margin: 0px;
    color: #868686;
    list-style-type: none;
    padding: 6px 10px;
    border-radius: 30px;
    border:1px solid #e5e7eb;
    margin-right: 10px;
}
.tag_checked{
    cursor: pointer;
    font-weight: bold;
    margin: 0px;
    color: #FFF;
    list-style-type: none;
    padding: 6px 10px;
    border-radius: 30px;
    border:1px solid #8247E5;
    margin-right: 10px;
    background:#8247E5;
}
.tags_tip{
    height: 40px;
    line-height: 40px;
    display: flex;
}
.tags_tips_num{
    color: #8247E5;
}


@media only screen and (max-width: 576px) {
    .tags{
        font-size: 12px;
    }
}