:root {
    --main-color: #111827;
    --second-color:#6b7280;
    --border-color:#e5e7eb;
    --link-color:#243277;
    --bg-color:#fff;
    --body-color:#fff;
  }
  
  [theme="dark"] {
    --main-color: #fff;
    --second-color:#7E858F;
    --border-color:#31363C;
    --link-color:#477FEF;
    --bg-color:#171B21;
    --body-color:#0E1116;
  }
  
body{
    background: var(--body-color);
}

.news {
    height: auto;
    align-items: center;
}


.news_h {

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;

}

.changeTheme{
    height: 50px;
    width: 50px;
    border: 1px solid var(--border-color);
    background: var(--bg-color);
    border-radius: 4px;
    margin-left: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}


.noise_btn {
    border: 1px solid var(--border-color);
    cursor: pointer;
    width: 160px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    color: var(--main-color);
    margin-right: 30px;
    background: var(--bg-color);

}

.noise_menus {
    display: flex;
    align-items: center;

}

.noise_select {
    width: 300px;
    margin-right: 30px;
}

.noise_item {
    background: var(--bg-color);
    width: cacl(96%-42px);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin: 0px auto;
    padding: 20px;
    margin-top: 20px;
 
}
.noise_avatar{
    width: 28px;
    height: 28px;
    border-radius: 100px;
}

.noise_item a{
    color: #000;
}

.noise_image {
    width: 120px;
    height: 120px;
    overflow: hidden;
    object-fit: cover;
    object-position: center top;
    margin-left: 10px;
}


.noise_content_top{
    justify-content: space-between;
    overflow: hidden;
    width: 100%;
    display: flex;
}

.noise_title {
    color: var(--main-color);
    font-size: 28px;
    display: flex;
    /* -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical; */
}
.noise_title_content:hover{
    text-decoration: underline;
}
.noise_describtion {
    color: var(--second-color);;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: 12px;
}

.noise_author {
    display: flex;
    align-items: center;
}



.noise_info {
    width: 100%;
    margin-top: 20px;
    align-items: center;
    display: flex;
    justify-content:space-between;
}

.noise_date {
    height: 32px;
    line-height: 32px;
    color: var(--second-color);;
}

.noise_operations{
    display: flex;
}
.noise_operations_delete{
    color: #FF6464;
    cursor: pointer;
    font-weight: bold;
    margin: 0px;
    list-style-type: none;
    border-radius: 6px;
    margin-left: 20px;
}

.deleteNoiseButton{
    text-align: center;
    background: #FF6464;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-radius: 6px;
    margin: 0px auto;
    margin-top: 20px;
}
.noise_operations_view{
    cursor: pointer;
    font-weight: bold;
    margin: 0px;
    color: var(--main-color);
    list-style-type: none;
    padding: 6px 20px;
    border-radius: 6px;
    border:1px solid var(--border-color);
  
}
.noise_operations_like{
    cursor: pointer;
    font-weight: bold;
    margin: 0px;
    list-style-type: none;
    margin-left: 20px;
}
a{
    text-decoration: none;
}

.twitterAvatar{
    margin-top: 8px;
    margin-right: 10px;
    display: block;
    overflow: hidden;
    border-radius: 2px;
    width: 28px;
    height: 28px;
    float: left;
}
.noise_tags{
    width: 50%;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    flex-wrap:wrap;
}
.noise_title:hover{
    color: var(--link-color);
}
.noise_tags li{
    color: var(--main-color);
    cursor: pointer;
    font-weight: 300;
    list-style-type: none;
    margin-right: 20px;
    padding: 2px 4px;
}
.noise_tags li:hover{
    text-decoration: underline;
    border-radius: 2px;
    padding: 2px 4px;
}
.tagPrefix{
    color: var(--second-color);
}
.likeanddelete{
    display: flex;
    align-items: center;
}
.twitterLink{
    margin-right: 20px;
}
.loading{
    font-size: 16px;
    width: 100%;
    text-align: center;
}
.sharelink{
    color: var(--link-color);
}
.sharelink:hover{
    text-decoration: underline;
}
.loadContent{
    text-align: center;
    color: var(--main-color);
}
@media only screen and (max-width: 576px) {
    .news {
        margin-top: 0px;
    }
    .news_h{
        align-items: center;
        flex-direction: column;
    }
    .noise_menus {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .noise_tit{
        font-size: 24px;
    }
    .noise_btn {
        width: 100%;
        margin-right: 0px;
        margin-top: 20px;
    }
    .noise_select{
        width: 100%;
        margin-top: 20px;
        margin-right: 0px;
    }
    .changeTheme{
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
    }
    .noise_info{
        align-items:start;
        flex-direction: column;
    }
    .noise_describtion{
        font-size: 12px;
    }
    .noise_title{
        line-height: 20px;
        display: flex;
        font-size: 14px;
        flex-direction: row;
        align-items: center;
    }
    .noise_title_content{
 
      margin-top: 4px;
    }
    .noise_operations{
        display: flex;
        width: 100%;
        justify-content:space-between;
        margin-top: 20px;
    }
    .noise_tags li{
        font-size: 12px;
    }
    .noise_tags{
        width: 100%;
    }
    .noise_image{
        display: none;
    }
    .noise_date{
        font-size: 12px;
    }
 
}