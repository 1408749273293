.header_bg{
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid #e5e7eb;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    width: 90%;
    max-width: 1000px;
    margin: 0px auto;
}
.slogan{
    color: #6b7280;
}

@media only screen and (max-width: 576px) {
    .slogan{
        display: none;
    }
}
