.noise_input{
    border: 1px solid #e5e7eb;
    height: 40px;
    line-height: 40px;
    margin-bottom: 20px;
}
.noise_textare{
    border: 1px solid #e5e7eb;
    margin-bottom: 20px;
}
.noise_publish{
    cursor: pointer;
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border-radius: 6px;
    background: #000;
    color: #fff;
    margin-right: 40px;
    margin-top: 20px;
}
.noise_error{
    width: 100%;
    text-align: center;
    color: #FF6464;
    margin-top: 20px;
}
.ant-tabs .ant-tabs-tab{
    color: #6b7280;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #000;
}
.ant-tabs .ant-tabs-ink-bar{
    background: #000;
}

.ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    color: #999;
} 


.ant-tabs .ant-tabs-tab+.ant-tabs-tab :active{
    color: #999;
}
.ant-tabs .ant-tabs-tab+.ant-tabs-tab :active{
    color: #999;
}
.ant-tabs .ant-tabs-tab-btn:active {
    color: #999;
}
.ant-checkbox-inner::after {
    width: 13.714286px;
    height: 23.142857px;
 }
.ant-checkbox-checked .ant-checkbox-inner {
    border: 1px solid #000 !important;
    background-color: #000 !important;
    border-color: #000 !important;
  }
  .ant-tabs-top >.ant-tabs-nav{
    margin-bottom: 0px;
  }
.automatic_tip{
    font-size: 12px;
    /* padding: 0px 0px 20px; */
    color: hsl(0, 0%, 50%);
}
.automatic_error{
    font-size: 12px;
    color: #FF6464;
}
.automatic_form{
    display: flex;
    margin-top: 20px;
}
.automatic_input{
    border: 1px solid #e5e7eb;
    height: 40px;
    line-height: 40px;

}
.automatic_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    width: 120px;
    background: #000;
    color:#fff;
}
.react-select-17-placeholder{
    color: #000;
}
.automatic_info{
    display: flex;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header{
    padding: 0px;
}
.automatic_container{
    margin: 20px 0px;
}
.ant-collapse-ghost >.ant-collapse-item >.ant-collapse-content >.ant-collapse-content-box{
    padding: 0px;
}
.noise_token{
    margin-top: 20px;
}
.selectPlaceholder{
    font-weight: normal;
    color: #BFBFBF;
}